/////  Font Family //////
$font-family-base: "Open Sans", sans-serif;
$font-sans-serif-bundle: "Open Sans", sans-serif;

///// Color //////
$raven: #68717a;
$grayishblue: #abb5be;
$grayishorange: #f7f5f4;
$palebud: #eeeae8;
$light-gray: #a8b5c1;
$blue2: #0000ee;
$titanium-white: #e4e4e4;
$gray99: #999999;
$whitesmoke: #efefef;
$yellow-orange: #FF9040;
$parpal :#AF2BBF;
$parpal-pulse-lighter : #F7EAF9;
$parpal-pulse : #f5dbf9;

/////  Webpixel Color //////
$primary: #152536;
$success: #00a88d;
$secondary: #fdcf76;
$danger: #e01522;

/////  Body //////
$body-bg: #f8f8f8;
$body-color: $primary;

/////  Headings //////
$headings-color: $primary;

///// Navbar //////
$navbar-dark-nav-link-hover-bg: transparent;
$nav-tabs-border-width: 0px;
$nav-tabs-link-padding-y: 0.5rem;
$nav-tabs-link-padding-x: 1rem;
$nav-tabs-link-margin-x: 0rem;

///// Nav Tab //////
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: transparent;

/////  DropDown //////
$dropdown-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: $primary;
$dropdown-divider-bg: #dcdcdc;
$dropdown-min-width: 13.68rem;

///// Button //////
$body-color: $primary;
$btn-font-family: "Futura";
$btn-border-radius: 50rem;
$btn-padding-x: 2rem;
$btn-padding-y: 0.75rem;
$btn-disabled-opacity: 0.55;

///// Button-lg //////
$btn-font-size-lg: 1.188rem;
$btn-padding-y-lg: 0.875rem;
$btn-border-radius-lg: 50rem;

///// Button Link //////
$link-decoration: underline;
$link-hover-decoration: underline;

/////  Card //////
$card-border-color: #cccccc;

/////  Form Control //////
$input-color: $primary;
$input-bg: #fff;
$input-border-color: $grayishblue;
$input-box-shadow: none;
$input-padding-y: 0.472rem;
$input-padding-x: 0.5rem;

/////  Form Label //////
$form-label-font-weight: 600;
$form-label-font-size: 1rem;
$form-label-color: $primary;

///// Input Group //////
$input-group-addon-bg: transparent;
$input-group-addon-color: $primary;

///// Form Check //////
$form-check-input-border-color: $grayishblue;
$form-check-input-checked-bg-color: $primary;

///// Table //////
$table-bg: #fff;
$table-th-font-size: 1rem;
$table-th-font-weight: 600;
$table-cell-font-size: 1rem;
$table-cell-padding-y: 0.75rem;
$table-th-text-transform: capitalize;
$table-cell-padding-x: 1rem;
$table-th-spacer-y: 0.5rem;

///// Modal //////
$modal-content-bg: $grayishorange;
$modal-header-padding-y: 0.75rem;
$modal-footer-gap: 0;
$modal-header-border-width: 0px;
$modal-sm: 522px;
$modal-md: 540px;
$modal-lg: 616px;
$modal-xl: 896px;

///// Tooltip //////
$tooltip-opacity: 1;

/////  Custom Colors //////
$custom-colors: (
  "grayish-blue": $grayishblue,
  "grayish-orange": $grayishorange,
  "titanium-white": $titanium-white,
  "gray99": $gray99,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1040px,
  xxl: 1262px,
);

@import "@webpixels/css/core/functions";
@import "@webpixels/css/core/variables";
@import "@webpixels/css/core/maps";
@import "@webpixels/css/core/mixins";

$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop(
  $utilities-colors,
  rgba-css-var,
  "$key",
  "text"
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// Import Bootstrap / Webpixel
@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components";
@import "@webpixels/css/extras";

@import "@webpixels/css/utilities";
@import "bootstrap/scss/utilities/api";

// Tostify
.Toastify__toast-container {
  --toastify-toast-width: auto;
  .Toastify__close-button {
    margin: 9px 0px 0px 9px;
  }
}